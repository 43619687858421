<!--
	Last modified: 2023/02/21 09:10:20
-->
<template>
	<table
		class="c-structured-data"
		:class="fullWidth && 'c-structured-data--full-width'"
	>
		<slot></slot>

		<tbody :class="tbodyClassNames">
			<tr
				v-for="(tr, rIndex) in computedCells"
				:key="`row-r${rIndex}`"
				:class="[
					'c-structured-data__row',

					{
						'c-structured-data__row--sticky':
							firstRowSticky &&
							useFirstRowAsHeader &&
							rIndex === 0,
					},
				]"
			>
				<Component
					:is="tc.scope ? 'th' : 'td'"
					v-for="(tc, cIndex) in tr"
					:key="`cell-r${rIndex}-c${cIndex}`"
					:class="[
						'c-structured-data__cell',
						{
							'c-structured-data__cell--sticky':
								firstColumnSticky &&
								useFirstColumnAsHeader &&
								cIndex === 0,
						},
					]"
					v-bind="{ scope: tc.scope }"
				>
					<slot
						name="cell"
						:html="tc.value"
						:is-row-header="useFirstRowAsHeader && rIndex === 0"
						:is-column-header="
							useFirstColumnAsHeader && cIndex === 0
						"
					>
						<div v-html="tc.value"></div>
					</slot>
				</Component>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'StructuredData',

	props: {
		cells: {
			type: Array,
			required: true,
		},
		useFirstRowAsHeader: {
			type: Boolean,
			default: false,
		},
		useFirstColumnAsHeader: {
			type: Boolean,
			default: false,
		},
		firstRowSticky: {
			type: Boolean,
			default: false,
		},
		firstColumnSticky: {
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		tbodyClassNames: {
			type: [String, Object, Array],
			default: null,
		},
	},

	computed: {
		computedCells() {
			return this.cells.map((row, rIndex) => {
				if (Array.isArray(row)) {
					return row.map((cell, cIndex) => {
						const rowScoped =
							cIndex === 0 && this.useFirstColumnAsHeader;

						const colScoped =
							rIndex === 0 && this.useFirstRowAsHeader;

						return {
							...cell,
							scope:
								cell.scope ||
								(colScoped ? 'col' : rowScoped && 'row'),
						};
					});
				}

				return [];
			});
		},
	},
};
</script>

<style lang="postcss">
:where(.c-structured-data) {
	isolation: isolate;
	display: block;
	text-align: left;
}

:where(.c-structured-data tbody) {
	display: block;
	overflow: auto;
}

:where(.c-structured-data__row) {
	width: 100%;
}

:where(.c-structured-data__cell--sticky) {
	position: sticky;
	left: 0;
	z-index: 10;
}

:where(.c-structured-data__row--sticky) {
	position: sticky;
	top: 0;
	z-index: 20;
}

:where(.c-structured-data__cell) {
	max-width: min(24em, 60vw);
}
:where(.c-structured-data--full-width .c-structured-data__cell) {
	width: 100vw;
	max-width: unset;
}
:where(.c-structured-data__cell > div) {
	display: block;
	width: max-content;
	max-width: 100%;
}
:where(.c-structured-data--full-width .c-structured-data__cell > div) {
	min-width: 100%;
}
</style>
