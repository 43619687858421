<template>
	<div v-if="table" :id="`block-${$vnode.key}`" class="c-block-list-table">
		<LongReadTarget
			v-if="title"
			class="relative mb-2xs/v z-10"
			:container-id="`block-${$vnode.key}`"
			:link-to-toc="{
				name: `Spring til &quot;${title}&quot; i indholdsfortegnelsen`,
				attrs: {
					'data-focus-ignore': true,
				},
			}"
			:title="title"
			:disabled="!showTitleInToc"
		>
			<BaseH2 :id="`block-${$vnode.key}__heading`" v-html="title" />
		</LongReadTarget>

		<StructuredData
			:id="`block-${$vnode.key}__table`"
			class="s-structured-data"
			:cells="table.cells"
			:aria-labelledby="
				title
					? `block-${$vnode.key}__heading block-${$vnode.key}__table`
					: null
			"
			:use-first-row-as-header="table.useFirstRowAsHeader"
			:use-first-column-as-header="table.useFirstColumnAsHeader"
			full-width
		>
			<template #cell="{ html, isRowHeader, isColumnHeader }">
				<div
					class="s-rich-text s-rich-text--monotone text-body-xs"
					:class="(isRowHeader || isColumnHeader) && 'font-medium'"
					v-html="html"
				></div>
			</template>
		</StructuredData>
	</div>
</template>

<script>
import { LongReadTarget } from '~/components/shared/LongRead';
import StructuredData from '~/components/shared/StructuredData';

export default {
	name: 'BlockListTable',

	components: {
		LongReadTarget,
		StructuredData,
	},

	inheritAttrs: false,

	props: {
		title: String,
		showTitleInToc: Boolean,
		table: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-table table {
	--padding-left: var(--theme-layout-margin, var(--theme-layout-margin--sm));
	--padding-right: var(--theme-layout-margin, var(--theme-layout-margin--sm));
	width: calc(100% + var(--padding-left, 0px) + var(--padding-right, 0px));
	margin-left: calc(var(--padding-left, 0px) * -1);
}
.c-block-list-table tbody {
	padding-left: calc(var(--padding-left, 0px));
}
.c-block-list-table tr:after {
	content: '';
	display: inline-block;
	padding-right: calc(var(--padding-right, 0px));
}

@screen >=1024 {
	.c-block-list-table table {
		--padding-left: 0px;
	}
}

@screen >=1441 {
	.c-block-list-table table {
		--padding-right: var(
				--theme-layout-margin,
				var(--theme-layout-margin--sm)
			) +
			(
				var(--visual-viewport-width, 100vw) -
					var(--theme-layout-max, 1440px)
			) / 2;
	}
}
</style>
