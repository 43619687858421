var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.table)?_c('div',{staticClass:"c-block-list-table",attrs:{"id":("block-" + (_vm.$vnode.key))}},[(_vm.title)?_c('LongReadTarget',{staticClass:"relative mb-2xs/v z-10",attrs:{"container-id":("block-" + (_vm.$vnode.key)),"link-to-toc":{
			name: ("Spring til \"" + _vm.title + "\" i indholdsfortegnelsen"),
			attrs: {
				'data-focus-ignore': true,
			},
		},"title":_vm.title,"disabled":!_vm.showTitleInToc}},[_c('BaseH2',{attrs:{"id":("block-" + (_vm.$vnode.key) + "__heading")},domProps:{"innerHTML":_vm._s(_vm.title)}})],1):_vm._e(),_vm._v(" "),_c('StructuredData',{staticClass:"s-structured-data",attrs:{"id":("block-" + (_vm.$vnode.key) + "__table"),"cells":_vm.table.cells,"aria-labelledby":_vm.title
				? ("block-" + (_vm.$vnode.key) + "__heading block-" + (_vm.$vnode.key) + "__table")
				: null,"use-first-row-as-header":_vm.table.useFirstRowAsHeader,"use-first-column-as-header":_vm.table.useFirstColumnAsHeader,"full-width":""},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
				var html = ref.html;
				var isRowHeader = ref.isRowHeader;
				var isColumnHeader = ref.isColumnHeader;
return [_c('div',{staticClass:"s-rich-text s-rich-text--monotone text-body-xs",class:(isRowHeader || isColumnHeader) && 'font-medium',domProps:{"innerHTML":_vm._s(html)}})]}}],null,false,892709210)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }