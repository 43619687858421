var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"c-structured-data",class:_vm.fullWidth && 'c-structured-data--full-width'},[_vm._t("default"),_vm._v(" "),_c('tbody',{class:_vm.tbodyClassNames},_vm._l((_vm.computedCells),function(tr,rIndex){return _c('tr',{key:("row-r" + rIndex),class:[
				'c-structured-data__row',

				{
					'c-structured-data__row--sticky':
						_vm.firstRowSticky &&
						_vm.useFirstRowAsHeader &&
						rIndex === 0,
				} ]},_vm._l((tr),function(tc,cIndex){return _c(tc.scope ? 'th' : 'td',_vm._b({key:("cell-r" + rIndex + "-c" + cIndex),tag:"Component",class:[
					'c-structured-data__cell',
					{
						'c-structured-data__cell--sticky':
							_vm.firstColumnSticky &&
							_vm.useFirstColumnAsHeader &&
							cIndex === 0,
					} ]},'Component',{ scope: tc.scope },false),[_vm._t("cell",function(){return [_c('div',{domProps:{"innerHTML":_vm._s(tc.value)}})]},{"html":tc.value,"isRowHeader":_vm.useFirstRowAsHeader && rIndex === 0,"isColumnHeader":_vm.useFirstColumnAsHeader && cIndex === 0})],2)}),1)}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }